import React from "react";
import { connect, Provider } from "react-redux";
import { bindActionCreators } from "redux";
import { StyleSheet, Text, View, Platform } from "react-native";
import store from "../store";
import { Switch, Router, Route, Link } from "../react-router";

import Sidebar from "./components/Layout/Sidebar";
import MiniDrawer from "./components/Layout/Sidebar/minidrawer";
//import './index.css';

import Home from "./pages/Home";
import Login from "./pages/Login";
import ResetPassword from "./pages/Login/recovery";

import Search from "./pages/Search";
import Profile from "./pages/Search/Profile";


import LauncherDialog from "./components/Loading/LauncherLoading";
import Header from "./components/Layout/Header";
import { themeStyle } from "./components/Layout/Header/style";
import Footer from "./components/Layout/Footer";
import BottonNav from "./components/Layout/BottonNav";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, Snackbar, Slide } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { Redirect } from "react-router-dom";

import { setAuth } from "./actions/authAction";
import { setSnackbar, setTimer, setProfileDialog } from "./actions/appActions";
// Theme
import {ptBR} from "@material-ui/core/locale";
import History from "./pages/history";
import Favorites from "./pages/favorites";
import { MainDialog } from "./components/Custom/Dialog";
import Dependents from "./pages/Dependents";
import CreateDemand from "./pages/Dependents/create";
import UserProfile from "./pages/UserProfile";
import Register from "./pages/Register";

const YamiTheme = createMuiTheme(themeStyle, ptBR);

const AppRouter = (props) => {
    const closeSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        props.setSnackbar({ open: false, message: "" });
    };

    function TransitionDown(props) {
        return <Slide {...props} direction="down" />;
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const authData = JSON.parse(localStorage.getItem("user_info"));

    return props.products !== undefined ? (
        <LauncherDialog />
    ) : (
        <ThemeProvider theme={YamiTheme}>
            {" "}
            {<Header setProfileDialog={props.setProfileDialog} />}
            <Container
                maxWidth="xl"
                style={{
                    overflow: "hidden",
                    paddingTop: 75,
                    background: "#f1f1f1",
                    //height: window.innerHeight,
                    minHeight: 800,
                    overflow: "auto",
                    marginBottom: 75
                }}
            >
                <Box>
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={props.snackbar.open}
                        autoHideDuration={3000}
                        onClose={closeSnack}
                        TransitionComponent={TransitionDown}
                        message={props.snackbar.message}
                        key="snb"
                    />

                    <Router>
                        <Switch>
                            <Route
                                path="/login"
                                exact={true}
                                render={() => <Login />
                                    /*!isAuth ? (
                                        <Home />
                                    ) : (
                                        <Redirect push to="/" />
                                    )*/
                                }
                            />{" "}
                            <Route
                                path="/register"
                                exact={true}
                                render={() => <Register /> }
                            />{" "}
                            <Route
                                path="/reset/:token"
                                exact={true}
                                component={ResetPassword}
                            />{" "}
                            <Route
                                path="/"
                                exact={true}
                                render={() =>
                                    <Home />
                                }
                            />{" "}
                            <Route
                                path="/myprofile"
                                exact={true}
                                render={() =>
                                    <UserProfile />
                                }
                            />{" "}
                            <Route
                                path="/search"
                                exact={true}
                                render={() =>
                                    <Search />
                                }
                            />{" "}
                            <Route
                                path="/doutor"
                                exact={true}
                                render={() => <Profile />}
                            />{" "}
                            <Route
                                path="/history"
                                exact={true}
                                render={() => <History />}
                            />{" "}
                            <Route
                                path="/favorites"
                                exact={true}
                                render={() => <Favorites />}
                            />{" "}

                            <Route
                                path="/dependents"
                                exact={true}
                                render={() => <Dependents />}
                            />{" "}

                            <Route
                                path="/dependents/create"
                                exact={true}
                                render={() => <CreateDemand />}
                            />{" "} 

                            <Route path="*">
                                <Box>
                                    <View> Pagina não encontrada. </View>
                                </Box>
                            </Route>
                        </Switch>
                        <Sidebar />{" "}
                    </Router>

                    <MainDialog setAuth={props.setAuth} auth={authData} profileDialog={props.profileDialog} setProfileDialog={(payload) => { props.setProfileDialog(payload) }} />
                </Box>
            </Container>
        </ThemeProvider>
    );
};
const styles = StyleSheet.create({
    container: {
        marginTop: 25,
        padding: 10,
    },
    nav: {
        flexDirection: "row",
        justifyContent: "space-around",
    },
});
const mapStateToProps = (store) => ({
    auth: store.authReducer.data,
    timer: store.appReducer.timer,
    snackbar: store.appReducer.snackbar,
    profileDialog: store.appReducer.profileDialog,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ setAuth, setSnackbar, setTimer, setProfileDialog }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
