import React, { useRef } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Select from '@mui/material/Select';
import { ScrollView, View } from "react-native-web";
import { GetHandler, PostHandler, UploadHandler } from "../../handlers";
import moment from "moment";
import Colors from "../../commons/Colors";
import { BRANDING } from "../../../branding";
import { ActivityIndicator, Linking } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

export function MainDialog(props) {
  const { setProfileDialog, profileDialog } = props;
  const handleClose = () => {
    setProfileDialog({ open: false });
    if (props.profileDialog?.onCancel) {
      props.profileDialog.onCancel();
    }
  };
  //console.log('MAIN DIALOG: ',props)

  return (
    <Dialog onClose={handleClose} open={profileDialog?.open}>
      {profileDialog?.type == "profile" && (
        <ProfileDialog {...props} handleClose={handleClose} />
      )}
      {profileDialog?.type == "OTP" && (
        <OTPDialog {...props} handleClose={handleClose} />
      )}

      {profileDialog?.type == "dependentes" && (
        <DependentsDialog {...props} handleClose={handleClose} />
      )}
      {profileDialog?.type == "help" && (
        <HelpDialog {...props} handleClose={handleClose} />
      )}
      {profileDialog?.type == "yesno" && (
        <YesNoDialog
          {...props}
          data={profileDialog}
          handleClose={handleClose}
        />
      )}
      {profileDialog?.type == "meet" && (
        <MeetDialog
          {...props}
          data={profileDialog}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
}

export function YesNoDialog(props) {
  const styles = {
    listItem: {
      borderBottomColor: "grey",
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
  };
  return (
    <div>
      <DialogTitle>{props?.data?.title ?? ""}</DialogTitle>
      <DialogContent>
        {props?.data?.bodyHTML != undefined ?
          <div>{(props?.data?.bodyHTML)}</div> :
          (props?.data?.body ?? props?.data?.title ?? "")
            ?.split("\n")
            ?.map((body) => {
              if (body.length < 5) {
                return;
              }
              return (
                <div>
                  <br />
                  <InputLabel style={{ lineHeigth: "1.5" }}>
                    {body ?? ""}
                  </InputLabel>
                </div>
              );
            })}
        <View style={{ marginVertical: 20 }}></View>
        <Divider
          style={{
            //backgroundColor: Colors.primary,
            marginHorizontal: 20,
            marginVertical: 20,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            props?.data?.singleButton == undefined ||
              props.data?.onSave != undefined
              ? props.data.onSave()
              : props.handleClose();

            props.handleClose();
          }}
        >
          {props?.data?.singleButton == undefined ? "Sim" : "OK"}
        </Button>
        {props?.data?.singleButton == undefined && (
          <Button
            variant={"contained"}
            color={"default"}
            onClick={props.handleClose}
          >
            Não
          </Button>
        )}
      </DialogActions>
    </div>
  );
}

export function MeetDialog(props) {
  const styles = {
    listItem: {
      borderBottomColor: "grey",
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
  };
  return (
    <div>
      <DialogTitle>{props?.data?.title ?? ""}</DialogTitle>
      <DialogContent>
        {(props?.data?.body ?? props?.data?.title ?? "")
          ?.split("\n")
          ?.map((body) => {
            if (body.length < 5) {
              return;
            }
            return (
              <div>
                <br />
                <InputLabel style={{ lineHeigth: "1.5" }}>
                  {body ?? ""}
                </InputLabel>
              </div>
            );
          })}
          <View style={{ marginVertical: 20 }}></View>
          {console.log(props?.data?.item)}
          <Button fullWidth variant="contained" color={"primary"}  onClick={() => {
            if (props?.data?.item?.videoconferencia?.link) {
              Linking.openURL(props?.data?.item?.videoconferencia?.link.replace('//ephyllus', '/ephyllus'));
              props.handleClose()
            }
          }}>Padrão
          </Button>
          <View style={{ marginVertical: 10 }}></View>
          {(props?.data?.item?.videoconferencia?.linkAlternativo != null) &&<Button fullWidth variant="contained" color="secoundary" onClick={() => {
            if (props?.data?.item?.videoconferencia?.linkAlternativo != null) {
              Linking.openURL(props?.data?.item?.videoconferencia?.linkAlternativo.replace('//ephyllus', '/ephyllus'));
              props.handleClose()
            }
          }}>
            Google Meet
          </Button>}
        <View style={{ marginVertical: 20 }}></View>
      </DialogContent>
      <DialogActions>
        
      </DialogActions>
    </div>
  );
}

export function OTPDialog(props) {
  const styles = {
    listItem: {
      borderBottomColor: "grey",
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
  };
  const [code, setCode] = React.useState(props?.code ?? "");

  const onSave = async () => {
    if (props?.onSave != undefined) {
      setProfileDialog({ open: false });
      props?.profileDialog?.onSave();
    }

    let user = JSON.parse(localStorage.getItem("user_info"));
    let data = {
      codigoConfirmacao: code,
      tipo: 'dependente'
    };

    let sendCode = await PostHandler(
      `pacientes/${props.auth?.id}/codigo/verificarCodigo?perfilId=${user?.perfilId}`,
      data,
      props.navigation
    );
    if (sendCode?.data?.success == false) {
      alert(sendCode?.data?.message)
    } else {
      alert(sendCode?.data?.message)
      props?.profileDialog?.onSave();
      props.handleClose();
    }


  };

  return (
    <div>
      <DialogTitle>Código de validação</DialogTitle>
      <DialogContent>
        <div>
          <InputLabel>
            {props?.profileDialog?.message}
          </InputLabel>
          <InputLabel>
            verifique seu {(props?.profileDialog?.from ?? []).join(', ')}
          </InputLabel>
          <div style={{ marginTop: 10 }}>
            <TextField
              size="small"
              style={{ width: window.innerWidth >= 680 ? 230 : "100%" }}
              label="Código de confirmação"
              variant="outlined"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </div>
        </div>
        <View style={{ marginVertical: 20 }}></View>
        <Divider
          style={{
            //backgroundColor: Colors.primary,
            marginHorizontal: 20,
            marginVertical: 20,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={async () => {
            await onSave();
          }}
        >
          Enviar
        </Button>
      </DialogActions>
    </div>
  );
}

export function ProfileDialog(props) {
  const [nome, setNome] = React.useState(props.auth?.nome ?? "");
  const [sobrenome, setSobrenome] = React.useState(props.auth?.sobrenome ?? "");
  const [email, setEmail] = React.useState(props.auth?.email ?? "");
  const [cpf, setCPF] = React.useState(props.auth?.cpf ?? "");
  const [telefone, setTelefone] = React.useState(props.auth?.celular ?? "");
  const [urlFoto, setUrlFoto] = React.useState(props.auth?.urlFoto ?? "");

  const onSave = async () => {
    if (props?.onSave != undefined) {
      setProfileDialog({ open: false });
      props.onSave();
    }

    let user = JSON.parse(localStorage.getItem("user_info"));
    user.nome = nome;
    user.sobrenome = sobrenome;
    user.email = email;
    user.celular = telefone;
    user.cpf = cpf;
    localStorage.setItem("user_info", JSON.stringify(user));
    props.setAuth(user);
    let data = {
      nome: nome,
      sobrenome: sobrenome,
      cpf: cpf,
      email: email,
      celular: telefone,
      perfilId: props?.auth?.perfilId ?? props?.auth?.perfilUsuarioId,
    };

    let change = await PostHandler(
      `pacientes/${props.auth?.id}/atualizar`,
      data,
      props.navigation
    );

    console.log(change);
    props.handleClose();
  };

  return (
    <div>
      <DialogTitle>Meu Perfil</DialogTitle>
      <DialogContent>
        {(cpf ?? "").length <= 10 ? (
          <div
            style={{
              marginBottom: 15,
              paddgingLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              backgroundColor: "#fff0f0",
              borderWidth: 1,
              borderColor: "#333",
            }}
          >
            O Campo CPF é Obrigatório.
          </div>
        ) : undefined}
        <View
          style={{
            paddingBottom: 25,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View>
            <label htmlFor="file">
              <Avatar
                style={{ borderWidth: 1, borderColor: Colors.primary }}
                src={(urlFoto ?? "").length > 0 ? urlFoto : undefined}
                alt={props.auth?.nome}
              >
                {" "}
                {(props.auth?.nome ?? "Pa")?.charAt(0)}{" "}
              </Avatar>
            </label>
          </View>
          <View style={{ marginStart: 25 }}>
            <small>* clique na foto ao lado para alterar.</small>
            <input
              id={"file"}
              style={{ display: "none" }}
              type={"file"}
              accept="image/*"
              onInput={async (e) => {
                console.log(e.target.files);
                let file = e.target.files[0];
                file.uri = e.target.value;
                let upload = await UploadHandler(props.auth?.id, {
                  file,
                  perfilId: props.auth?.perfilId,
                  web: true,
                });
                if (upload?.success) {
                  setUrlFoto(
                    upload?.data?.urlFoto + "?" + moment().format("x")
                  );

                  let user = JSON.parse(localStorage.getItem("user_info"));
                  user.nome = user.nome;
                  user.sobrenome = user?.sobrenome;
                  user.email = user?.email;
                  user.celular = user?.celular;
                  user.cpf = user?.cpf;
                  user.urlFoto =
                    upload?.data?.urlFoto + "?" + moment().format("x");

                  localStorage.setItem("user_info", JSON.stringify(user));
                  props.setAuth(user);
                }
                console.log("UPLOAD: ", upload, e.target.files[0]);
              }}
            />
          </View>
        </View>
        <div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: window.innerWidth >= 680 ? "row" : "column",
              justifyContent: window.innerWidth >= 680 ? "space-around" : undefined,
              width: window.innerWidth >= 680 ? 450 : "100%",
            }}
          >
            <div>
              <TextField
                size="small"
                style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
              <TextField
                size="small"
                style={{
                  width: window.innerWidth >= 680 ? 260 : "100%",
                }}
                label="Sobrenome"
                variant="outlined"
                value={sobrenome}
                onChange={(e) => {
                  setSobrenome(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: window.innerWidth >= 680 ? "flex" : undefined,
              flex: 1,
              flexWrap: "wrap",
              flexDirection: window.innerWidth >= 680 ? "row" : undefined,
              justifyContent: window.innerWidth >= 680 ? "space-around" : undefined,
              width: window.innerWidth >= 680 ? 450 : "100%",
              marginTop: 10,
            }}
          >
            <div>
              <TextField
                size="small"
                style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                label="Telefone"
                variant="outlined"
                value={telefone}
                onChange={(e) => {
                  setTelefone(e.target.value);
                }}
              />
            </div>
            <div style={{
              marginTop: window.innerWidth >= 680 ? undefined : 10
            }}>
              <TextField
                size="small"
                style={{ width: window.innerWidth >= 680 ? 260 : "100%" }}
                label="E-mail"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              width: window.innerWidth >= 680 ? 450 : "100%",
              marginTop: 10,
            }}
          >
            <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
              <TextField
                size="small"
                style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                label="CPF"
                variant="outlined"
                value={(cpf ?? "")?.replace(/\D/g, "")?.match(/.{1,3}/g)?.join(".")?.replace(/\.(?=[^.]*$)/, "-")}
                onChange={(e) => {
                  let val = e?.target?.value?.replace(/\D/g, "");
                  if (val.length > 11) {
                    return false;
                  }
                  setCPF(e.target.value);
                }}
              />
            </div>
            <div></div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={onSave}>
          Gravar
        </Button>
      </DialogActions>
    </div>
  );
}

export function HelpDialog(props) {
  const styles = {
    listItem: {
      borderBottomColor: "grey",
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
  };
  return (
    <div>
      <DialogTitle>Ajuda</DialogTitle>
      <DialogContent style={{ height: 301 }}>
        <ScrollView style={{ innerHeight: 150 }}>
          <div
            style={{
              marginStart: 15,
              marginTop: 10,
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <InputLabel
              style={{
                fontSize: 20,
              }}
            >
              {" "}
              Como Usar ?
            </InputLabel>
            <InputLabel style={{ lineHeight: "2.0" }}>
              1 - Faça a busca pelo seu profissional, filtrando por
              Especialidade, Formação, Valor, Gênero, Idioma e outros filtros.
              <br />
              2 - Selecione o profissional que melhor se identifica.
              <br />
              3 - Escolha uma data e horário para agendar a sua consulta.
              <br />4 - Efetue o pagamento e pronto.
            </InputLabel>

            <div style={{ marginTop: 15 }}>
              <InputLabel style={{ lineHeight: "2.0", textAlign: "center" }}>
                Clique para{" "}
                <a href="https://simdoctor.com.br">Mais Informações</a>
              </InputLabel>
            </div>
          </div>
          {/*<Divider
            bold={true}
            style={{
              //backgroundColor: Colors.primary,
              marginHorizontal: 20,
              marginVertical: 10,
            }}
          />
          <div>
            <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Realize seu cadastro em nosso aplicativo"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="1º"
                />
              )}
            />
            <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Selecione o Profissional que você deseja"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="2º"
                />
              )}
            />

            <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Escolha o dia e a hora para agendamento"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="3º"
                />
              )}
            />

          <ListItem
              titleNumberOfLines={2}
              titleStyle={{fontSize: 18}}
              style={styles.listItem}
              title="Realize o pagamento online"
              onPress={() => {
                this.setState({ ...this.state, changePassword: true });
              }}
              left={(props) => (
                <Avatar
                  style={{
                    //backgroundColor: Colors.secondary,
                  }}
                  size={24}
                  label="4º"
                />
              )}
                />
          </div> */}
        </ScrollView>
      </DialogContent>
    </div>
  );
}


export function DependentsDialog(props) {

  const [nome, setNome] = React.useState(props?.nome ?? "");
  const [foto, setFoto] = React.useState(props?.foto ?? undefined);
  const [img, setImg] = React.useState(undefined);
  const [sobrenome, setSobrenome] = React.useState(props?.sobrenome ?? "");
  const [filiacao, setFiliacao] = React.useState(props?.filiacao ?? "");
  const [datanascimento, setDatanascimento] = React.useState(props?.datanascimento ?? moment().format('yyyy-MM-DD'));
  const [cpf, setCPF] = React.useState(props?.cpf ?? "");
  const [sexo, setSexo] = React.useState(props?.sexo ?? "");
  const [requiredDocs, setRequiredDocs] = React.useState([]);
  const [docs, setDocs] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const fileContainer = useRef();

  const onLoad = async () => {
    await GetHandler(`configuracoes/documentosExigidos`, {
      perfilId: BRANDING?.perfilId,
      tipoDoc: 1,
      exibeCadastro: true
    }).then(res => {
      setRequiredDocs(res?.data?.data ?? []);
      setLoading(false)
    }).catch(e => {
      setLoading(false);
    });

  }
  if (requiredDocs.length == 0) {
    onLoad();
  }



  const onSave = async () => {
    setLoading(true);
    if (props?.onSave != undefined) {
      setProfileDialog({ open: false });
      props.onSave();
    }

    let data = {
      isDependente: true,
      pacIdResponsavel: props?.auth?.pacienteId ?? props?.auth?.id,
      nome: nome,
      sobrenome: sobrenome,
      sexo: sexo,
      cpf: cpf.replace(/\D/g, ""),
      tipoDependente: filiacao,
      dataNascimento: datanascimento,
      perfilId: props?.auth?.perfilId ?? props?.auth?.perfilUsuarioId,
      dependenteDoc: []
    };

    if (foto != undefined) {
      data.foto = foto;
    }

    docs.map((doc) => {
      data.dependenteDoc.push({
        id: doc?.id,
        arquivo: doc?.file
      })
    });

    let change = await PostHandler(
      `pacientes`,
      data,
      props.navigation
    );
    if (change?.data?.success == true) {
      alert(change?.data?.message ?? "Enviado com Sucesso!");
      await onLoad();
      props.handleClose();
      setLoading(false)
    } else {
      alert(change?.data?.message ?? "Problema ao cadastrar!");
    }
    setLoading(false)
  };

  const styles = {
    listItem: {
      borderBottomColor: "grey",
      borderBottomWidth: 1,
      marginHorizontal: 15,
    },
  };
  return (
    <div>
      <DialogTitle>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <span>Cadastrar Dependente</span>
          </View>
          <TouchableOpacity
            style={{ cursor: 'pointer' }}
            onPress={() => {
              props.handleClose();
            }}
          >
            <span>X</span>
          </TouchableOpacity>
        </View>
      </DialogTitle>
      <DialogContent style={{ height: 321 }}>
        <ScrollView style={{ innerHeight: 170 }}>
          <div>
            <View
              style={{
                paddingBottom: 25,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View>
                <label htmlFor="file">
                  <Avatar
                    style={{ borderWidth: 1, borderColor: Colors.primary }}
                    src={img}
                    alt={'send user profile image'}
                  >
                    {(nome.length > 0 ? nome : "Dep")?.charAt(0)}{" "}
                  </Avatar>
                </label>
              </View>
              <View style={{ marginStart: 25 }}>
                <small>* clique na foto ao lado para alterar.</small>
                <input
                  id={"file"}
                  style={{ display: "none" }}
                  type={"file"}
                  //accept="image/*"
                  onInput={async (e) => {
                    const foto = e.target.files[0];

                    if (foto) {
                      foto.uri = e.target.value;
                      let file = e.target.files[0];
                      file.uri = e.target.files[0].value

                      var reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onloadend = async function (e) {
                        setImg(await reader.result)
                      }
                      setFoto(foto)
                    }
                  }}
                />
              </View>
            </View>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              flexDirection: window.innerWidth >= 680 ? "row" : "column",
              justifyContent: window.innerWidth >= 680 ? "space-around" : undefined,
              width: window.innerWidth >= 680 ? 450 : "100%",
            }}
          >
            <div>
              <TextField
                size="small"
                style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                label="Nome"
                variant="outlined"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
              <TextField
                size="small"
                style={{
                  width: window.innerWidth >= 680 ? 140 : "100%",
                }}
                label="Sobrenome"
                variant="outlined"
                value={sobrenome}
                onChange={(e) => {
                  setSobrenome(e.target.value);
                }}
              />
            </div>

            <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
              <TextField
                size="small"
                style={{
                  width: window.innerWidth >= 680 ? 100 : "100%",
                }}
                label="Filiação"
                variant="outlined"
                value={filiacao}
                onChange={(e) => {
                  setFiliacao(e.target.value);
                }}
              />
            </div>
          </div>


          <div
            style={{
              display: window.innerWidth >= 680 ? "flex" : undefined,
              flex: window.innerWidth >= 680 ? 1 : undefined,
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              width: window.innerWidth >= 680 ? 450 : "100%",
              marginTop: 10
            }}
          >

            <div style={{ marginTop: window.innerWidth >= 680 ? 18 : 10 }}>
              <TextField
                size="small"
                style={{ width: window.innerWidth >= 680 ? 140 : "100%" }}
                label="CPF"
                variant="outlined"
                value={(cpf ?? "")?.replace(/\D/g, "")?.match(/.{1,3}/g)?.join(".")?.replace(/\.(?=[^.]*$)/, "-")}
                onChange={(e) => {
                  let val = e?.target?.value?.replace(/\D/g, "");
                  if (val.length > 11) {
                    return false;
                  }
                  setCPF(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: window.innerWidth >= 680 ? undefined : 10 }}>
              <TextField
                size="small"
                type="date"
                style={{ width: window.innerWidth >= 680 ? 140 : "100%", marginTop: 18 }}
                label="Data Nasc."
                variant="outlined"
                value={(datanascimento ?? "")}
                onChange={(e) => {

                  setDatanascimento(e.target.value);
                }}
              />
            </div>

            <View
              style={{
                flex: window.innerWidth >= 680 ? 0.8 : undefined,
                marginEnd: 15,
                marginTop: window.innerWidth >= 680 ? 0 : 10,
              }}
            >
              <InputLabel style={{ fontSize: 12, marginBottom: 5, marginLeft: 5, marginTop: 5 }} id="sexo-select-label">
                Sexo
              </InputLabel>
              <FormControl sx={{ m: 1 }} size="small">
                <Select
                  style={{ marginTop: -2 }}
                  size={'small'}
                  labelId="sexo-select-label"
                  id="sexo-select"
                  value={sexo}
                  //label="Sexo"
                  //aria-label="Sexo"
                  onChange={(e) => {
                    setSexo(e.target.value);
                  }}
                >
                  {([
                    { value: "Masculino", label: "Masculino" },
                    { value: "Feminino", label: "Feminino" }
                  ]).map((item) => (
                    <MenuItem
                      key={`sexo-${item?.value}`}
                      value={item?.value}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </View>
          </div>

        </ScrollView>
        {(requiredDocs ?? []).length > 0 ? (
          <div style={{ marginTop: 15 }}>
            <InputLabel style={{ marginTop: 25, marginBottom: 20, fontWeight: 'bold' }}>
              Faça o Envio dos Documentação
            </InputLabel>
            <div>
              {(requiredDocs ?? []).map(doc => {
                let exist = docs.find(f => f.id == doc?.id);
                return (
                  <div key={`docs-${doc.id}`} style={{ border: 1, marginTop: 20 }}>
                    <div style={{ marginBottom: 10 }}>
                      <InputLabel style={{ fontWeight: 'bold', textTransform: 'capitalize' }}> {doc?.nome} {doc.obrigatorio == 1 && (<b style={{ color: 'red' }}>*</b>)} </InputLabel>
                    </div>
                    <div>

                      <View style={{ flexDirection: 'row' }}>
                        <div>
                          <label htmlFor={`doc-${doc?.id}`}>
                            <a
                              style={{
                                cursor: 'pointer',
                                padding: 7,
                                backgroundColor: !exist ? Colors.primary : Colors.tertiary,
                                borderRadius: 5,
                                color: 'white',
                              }}>
                              {exist ? exist?.file?.name : "Escolher Documento"}
                            </a>
                          </label>
                        </div>
                        <div>
                          {exist && exist?.file?.name != undefined ?
                            (
                              <a href="#" style={{ marginLeft: 5, textDecoration: 'none' }} onClick={() => {
                                setLoading(true);
                                let ndocs = docs.filter(d => d.id != exist.id)
                                setDocs(ndocs);
                                setTimeout(() => { setLoading(false) }, 300);
                              }}>
                                Editar
                              </a>
                            ) : ''}
                        </div>
                      </View>
                      {(loading == false && <input
                        id={`doc-${doc?.id}`}
                        style={{ display: 'none' }}
                        type={"file"}
                        onInput={(e) => {
                          setLoading(true);
                          let file = e.target.files[0];
                          file.uri = e.target.value;
                          let files = docs;
                          const fileInd = docs.findIndex(f => f.id == doc.id);
                          if (fileInd > -1) {
                            files.splice(fileInd, 1);
                          } else {
                            files.push({ id: doc.id, file });
                          }
                          setDocs(files);
                          setTimeout(() => {
                            setLoading(false);
                          }, 300);
                        }}
                      />)}
                    </div>
                    <div style={{ marginTop: 10, textAlign: 'center', padding: 10, backgroundColor: Colors.alert, width: '25%' }}>
                      <small>{doc?.formatoArquivo?.nome}</small>
                    </div>
                  </div>
                )
              })}
              <div ref={() => fileContainer}></div>
            </div>
          </div>) : loading == true ? <div><ActivityIndicator /></div> : undefined}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant={"contained"} color={"primary"} onClick={onSave}>
          Gravar
        </Button>
      </DialogActions>
    </div>
  );
}