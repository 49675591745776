import React, { Component, Fragment } from "react";
import {
  ActivityIndicator,
  Button,
  Text,
  TextInput,
  View,
} from "react-native-web";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Card from "@material-ui/core/Card";
import RNPickerSelect from "react-native-picker-select";
import Colors from "../../commons/Colors";
//

import { setSnackbar, setTimer } from "../../actions/appActions";
import { Icon, InputLabel } from "@material-ui/core";
import { StyleSheet } from "react-native";
import HomeForm from "./components/form";
import { Image } from "@material-ui/icons";
import Search from "../Search";
import { AuthInternalHandler } from "../../handlers";
import { BRANDING } from "../../../branding";
import { Credentials } from "../../models/Credentials";
class Home extends Component {
  state = {
    allFilters: false,
    resultScreen: false,
    filtersData: [],
    skills: [],
    academic: [],
    callGroups: [],
    filter: {
      totalConsultasAtendidas: true,
      nome: "",
      sexo: [],
      tipoAtendimento: "video",
      valorConsulta: "0.00",
      valorConsultaMax: "550.00",
      especialidade: "",
      grupoAtendimentoId: [],
      idiomaId: [],
      nomeFormacao: [],
    },
    minValue: 50,
    maxValue: 350,
  };

  setResultScreen(visible) {
    this.setState({ resultScreen: visible });
  }

  async componentDidMount() {
    let token = JSON.parse(localStorage.getItem("auth_token") ?? {});

    //if (!token?.token) {
      await AuthInternalHandler(new Credentials(BRANDING?.internal));
    //}
  }
  render() {
    if (this.state.resultScreen) {
      return (
        <Search
          setResultScreen={(visible) => {
            this.setResultScreen(visible);
          }}
          filter={this.state.filter}
          setAllFilter={(show) => {
            this.setState({
              ...this.state,
              allFilters: true,
              resultScreen: false,
            });
          }}
        />
      );
    }

    return (
      <Fragment>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
          {window.innerWidth > 680 &&
            (<View
            style={{
              maxWidth: 400,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                width: 400,
              }}

            >
              <View style={{}}>
                <img
                  src={BRANDING?.filterLogo?.img}
                  style={{ backgroundSize: "cover", width: BRANDING?.filterLogo?.width }}
                />
              </View>

              <View style={{ alignItems: "center" }}>
                <img
                  src={require("../../../assets/images/imagem-principal.png")}
                  style={{ backgroundSize: "cover", width: 400 }}
                />
              </View>
            </View>
          </View>)
          }
          {window.innerWidth > 680 && (<View></View>)}
          <View style={{ width: window.innerWidth > 680 ? 790 : "99%", justifyContent: "center" }}>
            <HomeForm
              {...this.props}
              setResultScreen={(visible) => {
                this.setResultScreen(visible);
              }}
              filter={this.state.filter}
              setFilter={(filter) => {
                this.setState({ filter });
              }}
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginVertical: 25,
          }}
        >
          <InputLabel
            style={{
              fontSize: 24,
              maxWidth: 920,
              fontWeight: "bold",
              textAlign: "start",
              lineHeight: "1.5",
            }}
          >
            {BRANDING?.homeText ?? ""}
          </InputLabel>
        </View>
      </Fragment>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSnackbar, setTimer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
