export const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  link: {
    color: "#444",
    textDecoration: "none"
  }
};