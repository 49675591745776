import React, { Component, Fragment } from "react";
import { View, ScrollView } from "react-native-web";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//
import Colors from "../../commons/Colors";
//
import { setProfileDialog, setSnackbar, setTimer } from "../../actions/appActions";
import { toMoney } from "../../handlers/money";
import { DeleteHandler, GetHandler, PostHandler } from "../../handlers";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  List,
  Portal,
} from "@material-ui/core";
import { ArrowLeft, ArrowLeftSharp, CheckBox, Star } from "@material-ui/icons";
import ProfissionalItems from "./profissionalItems";
import moment from "moment";
import { Alert, Dimensions } from "react-native";
import { Link } from "react-router-dom";

class SearchList extends Component {
  state = {
    dataStart: moment(),
    profile: undefined,
    doctorData: undefined,
    showOrderpanel: false,
    scheduleData: {
      pacienteId: undefined,
      perfilId: undefined,
      doutorId: this.props.dados?.id,
      horario: undefined,
      data: moment().format("yyyy-MM-DD"),
      tipoAtendimento: this.props?.location?.tipoAtendimento ?? "video",
      //linkPagSeguro: true,
      linkAsaas: true,
      formaPagAsaas: "",
    },
    orderBy: [
      { column: "pontuacao.desc", label: "Avaliação", checked: false },
      {
        column: "totalConsultasAtendidas.desc",
        label: "Número de consultas",
        checked: false,
      },
      { column: "precoConsulta.desc", label: "Maior Preço", checked: false },
      { column: "precoConsulta.asc", label: "Menor Preço", checked: false },
      { column: "pontuacao.desc", label: "Tempo de trabalho", checked: false },
    ],
    results: [],
    pageOptions: undefined,
    refreshing: false,
  };

  async getData() {
    const gd = async () => { await this.props.getData(); }
    setInterval(function () {
      gd();
    }, 30000);
  }
  async setOrder(item) {
    let orderBy = this.state.orderBy;
    orderBy.find((order) => order.label == item.label).checked = !item.checked;
    this.setState({
      orderBy,
      showOrderpanel: false,
    });
    await this.props.getData(undefined, orderBy);
  }

  sendSchedule = async (item) => {
    let authData = JSON.parse(localStorage.getItem("user_info"));

    let scheduleData = this.state.scheduleData;
    scheduleData.doutorId = item?.id ?? item?.doutorId;
    scheduleData.perfilId = item?.perfilId;
    scheduleData.pacienteId = authData?.id;
    scheduleData.paciente = {
      nome: authData?.nome ? `${authData?.nome} ${authData?.sobrenome}` : "",
      cpf: authData?.cpf ?? "",
      email: authData?.email ?? "",
    };

    this.setState({ ...this.state, reloading: true });
    if (scheduleData.horario === undefined) {
      alert(
        "Selecione um horário\n\né necessário escolher um horário disponível para agendar a consulta"
      );
      this.setState({ ...this.state, reloading: false });
      return false;
    }
    /*if (!this.state.terms) {
      Alert.alert(
        "Termos e condições",
        " é necessário concordar com os termos e condições de agendamento"
      );
      this.setState({ ...this.state, reloading: false });
      return false;
    }*/
    this.setState({ ...this.state, reloading: true });
    if (
      !scheduleData.pacienteId ||
      !scheduleData.perfilId ||
      !scheduleData.doutorId
    ) {
      alert("Login Obrigatório\n\nFaça login para continuar!");
      /*this.props.navigation.navigate("profile", {
        refresh: true,
        schedule: scheduleData,
      });*/
    } else {
      if ((authData?.cpf ?? "").length <= 10) {
        this.props.setProfileDialog({ open: true, type: "profile" });
        return false;
      }
      const scheduling = await PostHandler(
        `consultas`,
        scheduleData,
        undefined
      );

      if (scheduling?.data?.success) {
        let body =
          "O seu horário foi reservado, e será confirmado após efetuar o pagamento !";
        body += "\nDeseja ser redirecionado para pagamento da sessão agora ?";
        this.props.setProfileDialog({
          title: "Atenção",
          body,
          open: true,
          type: "yesno",
          onSave: () => {
            window.open(
              scheduling?.data?.data?.linkCobranca?.linkCobranca,
              "_blank"
            );
            window.location.href = "/history";
          },
          onCancel: () => {
            window.location.href = "/history";
          },
        });
        window.location.href = "/history";
      } else {
        //console.log('AGAGAG:', scheduling)
        alert(
          "Problema ao agendar " +
          (scheduling?.data?.message ??
            "Houve um problema ao tentar efetuar o agendamento")
        );
      }
    }
    this.setState({ ...this.state, reloading: false });
  };
  async componentDidMount() {

  }
  render() {
    const status = [
      {
        cod: "noPay",
        color: "#d26900",
        name: "Pendente",
      },
      {
        cod: "naoConfirmado",
        name: "Não Confirmado",
        icon: "calendar-blank",
        color: "#987b01",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "confirmado",
        name: "Confirmado",
        icon: "calendar-check",
        color: "#04bc17",
        background: "#d6fcd9",
        material: true,
      },
      {
        cod: null,
        name: "Agendado",
        icon: "calendar-blank",
        color: "#04bc17",
        background: "#faf4d5",
        material: true,
      },
      {
        cod: "jaSeEncontra",
        name: "Aguardando",
        icon: "timer",
        color: "#008085",
        background: "#d1f8f5",
      },
      {
        cod: "estaSendoAtendido",
        name: "Em Atendimento",
        icon: "person",
        color: "#2558c9",
        background: "#d1ddf8",
      },
      {
        cod: "jaFoiAtendido",
        name: "Atendido",
        icon: "checkmark-circle-outline",
        color: "#8b8b8b",
        background: "#efefef",
      },
      {
        cod: "faltou",
        name: "Não Compareceu",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
      {
        cod: "desmarcado",
        name: "Desmarcado",
        icon: "close-circle-outline",
        color: "#a60606",
        background: "#f8d1d1",
      },
    ];
    return (
      <View>
        {(this.props.data ?? []).length > 0 ? (
          <View style={{ marginBottom: 60, zIndex: 889 }}>
            <ScrollView>
              {(this.props.data ?? []).map((item, key) => {
                let preco =
                  (item?.procedimentos ?? [])
                    .map(
                      (prc) => parseFloat(prc?.valor ?? "0.0") * prc?.qnt + ""
                    )
                    .reduce(
                      (prev, next) => parseFloat(prev) + parseFloat(next),
                      0
                    ) ?? 0;

                preco = (preco + item?.acrescimo ?? 0) - (item?.desconto ?? 0);

                const today = moment();
                const created = moment(item.dataCad ?? undefined);

                const dateData = {
                  years: Math.abs(
                    parseInt(today.format("yyyy")) -
                    parseInt(created.format("yyyy"))
                  ),
                  months: Math.abs(
                    parseInt(today.format("MM")) -
                    parseInt(created.format("MM"))
                  ),
                  days: Math.abs(
                    parseInt(moment().format("DD")) -
                    parseInt(
                      moment(moment().subtract(1, "month"))
                        .endOf("month")
                        .format("MM")
                    )
                  ),
                };

                //const today = moment(moment(), 'yyyy-MM-DD HH:mm:ss');
                const waiting = moment(
                  item.dataConsulta + " " + item.horaConsulta,
                  "yyyy-MM-DD HH:mm:ss"
                );

                var timeEstimated = moment.duration({
                  years: waiting.year() - moment().year(),
                  months: waiting.month() - moment().month(),
                  days: waiting.date() - moment().date(),
                  hours: waiting.hours() - moment().hours(),
                  minutes: waiting.minutes() - moment().minutes(),
                });
                console.log("TEMPO : ", timeEstimated);
                let pago =
                  [1, 2].includes(item?.videoconferencia?.videoPago) ||
                  item?.pago == true;
                let cancelado = ["faltou", "desmarcado"].includes(
                  item?.statusConsulta
                );

                item?.statusConsulta == "jaFoiAtendido"
                  ? status.find((s) => s.cod == item.statusConsulta)?.name
                  : "A " +
                    ["faltou", "desmarcado"].includes(item?.statusConsulta) ==
                    true
                    ? "CANCELADO"
                    : item?.pago == true ||
                    ([1, 2].includes(item?.videoconferencia?.videoPago)
                      ? "B " + "PAGO"
                      : "C " +
                      status.find((s) => s.cod == item.statusConsulta)?.name);

                return (
                  <View
                    style={{ marginBottom: 10 }}
                    key={`history-search-list-${key}`}
                  >
                    <Card>
                      <CardContent>
                        <View
                          style={{
                            flexDirection:
                              window.innerWidth <= 680 ? undefined : "row",
                            justifyContent:
                              window.innerWidth <= 680
                                ? "center"
                                : "space-between",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <View
                              style={{ width: "100%", alignItems: "center" }}
                            >
                              <img
                                alt={item?.doutor?.nome}
                                src={
                                  item?.doutor?.urlFoto ??
                                  require("../../../assets/empty_image.png")
                                }
                                style={{
                                  flex: 1,
                                  width: "100%",
                                  height: "100%",
                                  maxWidth: 100,
                                  borderRadius: "50%",
                                }}
                              />
                              <Link
                                style={{ textDecoration: "none" }}
                                to={{
                                  pathname: `/doutor`,
                                  state: item?.doutor,
                                }}
                              >
                                <Button
                                  style={{ marginTop: 10 }}
                                  variant={"contained"}
                                  color={"primary"}
                                >
                                  Ver Perfil
                                </Button>
                              </Link>
                            </View>
                          </View>
                          <View
                            style={{
                              marginStart: window.innerWidth <= 680 ? 0 : 36,
                              marginBottom: 10,
                              flex: 1,
                              flexDirection:
                                window.innerWidth <= 680 ? undefined : "row",
                              justifyContent:
                                window.innerWidth <= 680
                                  ? "center"
                                  : "space-between",
                            }}
                          >
                            <View style={{ marginEnd: 15 }}>
                              <InputLabel
                                style={{
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  marginBottom: 10,
                                  marginTop: 25,
                                }}
                              >
                                {item?.doutor.nomeDoutor}
                              </InputLabel>
                              <InputLabel
                                style={{
                                  marginBottom: 10,
                                  marginTop: 25,
                                }}
                              >
                                Para : <b> {`${item?.paciente.nomePaciente} ${item?.paciente.sobrenomePaciente}`} </b>
                              </InputLabel>
                              {(item?.doutor?.especialidades ?? []).length >
                                0 && (
                                  <InputLabel style={{ marginBottom: 10 }}>
                                    Especialidades:{" "}
                                    <b>
                                      {(item?.doutor?.especialidades ?? [])
                                        ?.map((item) => item?.nome)
                                        ?.join(",")}
                                    </b>
                                  </InputLabel>
                                )}
                              <InputLabel style={{ marginBottom: 10 }}>
                                Data:{" "}
                                <b>
                                  {moment(item.dataConsulta).format(
                                    "DD/MM/yyyy"
                                  )}
                                </b>
                              </InputLabel>
                              <InputLabel style={{ marginBottom: 10 }}>
                                Horário :{" "}
                                <b>
                                  {moment(
                                    item.dataConsulta + " " + item.horaConsulta
                                  ).format("HH:mm")}
                                </b>
                              </InputLabel>
                              {/*(item.procedimentos ?? []).length > 0 ? (
                                <InputLabel
                                  style={{
                                    flex: 1,
                                    width: 210,
                                    flexWrap: "wrap",
                                    marginBottom: 10,
                                  }}
                                >
                                  <b>
                                    {(item.procedimentos ?? [])
                                      .map((proc) => proc.nome)
                                      .join(", ")}
                                  </b>
                                </InputLabel>
                                    ) : undefined*/}

                              <InputLabel style={{ marginBottom: 10 }}>
                                Preço :{" "}
                                <b>
                                  {(
                                    parseFloat(preco).toFixed(2) ?? "0.00"
                                  ).replace(".", ",")}
                                </b>
                              </InputLabel>
                              <InputLabel style={{ marginBottom: 10 }}>
                                Duração :{" "}
                                <b>
                                  {(item.procedimentos ?? []).length == 0
                                    ? 50
                                    : (item.procedimentos ?? [])
                                      .map((proc) => proc.duracao)
                                      .reduce(
                                        (prev, next) =>
                                          parseFloat(prev) + parseFloat(next),
                                        0
                                      ) ?? 30}{" "}
                                  minutos
                                </b>
                              </InputLabel>
                              <InputLabel style={{ marginBottom: 10 }}>
                                Tipo de Consulta :{" "}
                                <b>
                                  {item?.videoconferencia?.status == true
                                    ? "Videoconsulta"
                                    : "Presencial"}
                                </b>
                              </InputLabel>
                            </View>
                            {/**  CENTER  */}
                            <View
                              style={{
                                marginEnd:
                                  window.innerWidth <= 680 ? undefined : 15,
                                width: window.innerWidth <= 680 ? "100%" : 500,
                                flexWrap:
                                  window.innerWidth <= 680 ? undefined : "wrap",
                                flexDirection:
                                  window.innerWidth <= 680 ? "row" : undefined,
                                justifyContent: "center",
                                flexDirection:
                                  window.innerWidth <= 680
                                    ? "column"
                                    : undefined,
                                alignItems:
                                  window.innerWidth <= 680
                                    ? "center"
                                    : undefined,
                              }}
                            >
                              {cancelado ? (
                                <View>
                                  <InputLabel
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      marginBottom: 10,
                                      marginTop: 25,
                                    }}
                                  >
                                    {item?.statusConsulta == "desmarcado"
                                      ? `Desmarcado pelo ${item?.desmarcadoPor ?? "Doutor"
                                      } ${item?.razaoDesmarcacao ?? ""}`
                                      : `Paciente Faltou ${item?.motivoFalta ?? ""
                                      }`}
                                  </InputLabel>
                                </View>
                              ) : undefined}
                              {[
                                "jaFoiAtendido",
                                "faltou",
                                "desmarcado",
                              ].includes(item?.statusConsulta) == false &&
                                moment(
                                  item.dataConsulta + " " + item.horaConsulta
                                ) >= moment() &&
                                pago == true ? (
                                <View>
                                  <InputLabel
                                    style={{
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      width:
                                        window.innerWidth <= 680
                                          ? "100%"
                                          : undefined,
                                      marginBottom: 10,
                                      marginTop: 30,
                                    }}
                                  >
                                    Faltam{" "}
                                    {timeEstimated?.months() > 0
                                      ? `${timeEstimated?.months()}  mes(es) e `
                                      : ""}
                                    {timeEstimated?.days() > 0
                                      ? `${timeEstimated?.days()} dia(s)  e `
                                      : ""}
                                    {timeEstimated?.hours() > 0
                                      ? `${timeEstimated?.hours()} hora(s) e `
                                      : ""}
                                    {timeEstimated?.minutes() > 0
                                      ? `${timeEstimated?.minutes()} minuto(s)`
                                      : ""}
                                  </InputLabel>

                                  {item?.videoconferencia?.status == true ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        style={{
                                          width: 280,
                                          color: "#FFF",
                                          backgroundColor: status.find(
                                            (s) => s.cod == "estaSendoAtendido"
                                          )?.color,
                                        }}
                                        onClick={() => {
                                          this.props.setProfileDialog({
                                            title: "Atendimento Via: ",
                                            body: "Selecione um provedor para a video conferência",
                                            item: item,
                                            open: true,
                                            singleButton: true,
                                            type: "meet",
                                            onSave: () => { },
                                            onCancel: () => { },
                                          });
                                          // if (item?.videoconferencia?.link) {
                                          //   window
                                          //     .open(
                                          //       item?.videoconferencia?.link,
                                          //       "_blank"
                                          //     )
                                          //     .focus();
                                          // } else {
                                          //   alert(
                                          //     "Erro interno, fale com o suporte"
                                          //   );
                                          // }
                                        }}
                                      >
                                        {"ENTRAR NA VIDEO CONSULTA"}
                                      </Button>

                                      <InputLabel
                                        style={{
                                          marginTop: 15,
                                          marginBottom: 15,
                                          fontSize: 14,
                                        }}
                                      >
                                        * Entre pelo menos 5 minutos antes para
                                        verificar
                                        <br />
                                        {"   "} sua conexão,câmera e microfone
                                      </InputLabel>
                                    </>
                                  ) : undefined}
                                </View>
                              ) : (
                                <View
                                  style={{
                                    flex:
                                      window.innerWidth <= 680
                                        ? "auto"
                                        : undefined,
                                  }}
                                >
                                  <InputLabel
                                    style={{
                                      color: Colors.secondary,
                                      fontSize: 18,
                                      marginTop:
                                        window.innerWidth <= 680 ? 10 : 0,
                                      textAlign:
                                        window.innerWidth <= 680
                                          ? "start"
                                          : undefined,
                                    }}
                                  >
                                    {item?.statusConsulta == "jaFoiAtendido"
                                      ? "Consulta Finalizada"
                                      : moment(
                                        item.dataConsulta +
                                        " " +
                                        item.horaConsulta
                                      ) < moment()
                                        ? cancelado
                                          ? ""
                                          : "Consulta Expirada."
                                        : pago ||
                                          (cancelado &&
                                            ["jaFoiAtendido"].includes(
                                              item?.statusConsulta
                                            ))
                                          ? undefined
                                          : cancelado
                                            ? ""
                                            : "Pagamento Pendente"}
                                  </InputLabel>
                                </View>
                              )}
                            </View>
                          </View>
                          <View style={{ justifyContent: "center" }}>
                            <View
                              style={{
                                paddingBotom: 10,
                                paddingHorizontal: 0,
                                marginBottom: 20,
                              }}
                            >
                              {pago || cancelado ? (
                                <><View
                                  style={{
                                    color: "#FFF",
                                    width:
                                      window.innerWidth <= 680 ? "100%" : 170,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingRight: 35,
                                    paddingLeft: 35,
                                    borderRadius: 5,
                                    backgroundColor:
                                      item?.statusConsulta == "jaFoiAtendido"
                                        ? "#008085"
                                        : cancelado == true
                                          ? "#a9a9a9"
                                          : status.find(
                                            (s) => s.cod == "confirmado"
                                          )?.color,
                                  }}
                                >
                                  <InputLabel
                                    key={`pg`}
                                    style={{
                                      color: "#FFF",
                                      textAlign: "center",
                                    }}
                                  >
                                    {cancelado
                                      ? "CANCELADO"
                                      : item?.statusConsulta == "jaFoiAtendido"
                                        ? "ATENDIDO"
                                        : "PAGO"}
                                  </InputLabel>
                                </View>
                                {
                                  ((item?.videoconferencia?.videoPago == true || item?.pago == 1 )) && (<View>
                                 
                                  <Button
                                    variant="contained"
                                    style={{
                                      width:
                                        window.innerWidth <= 680 ? 110 : 170,
                                      marginTop:
                                        window.innerWidth <= 680 ? 0 : 10,
                                      backgroundColor: status.find(
                                        (s) => s.cod == "estaSendoAtendido"
                                      )?.color,
                                      color: "#ffffff",
                                    }}
                                    onClick={async () => {
                                      window.open(item?.urlRecibo, '_blank');
                                     }}
                                  > Recibo
                                  </Button>
                                </View>)}
                                </>

                              ) : undefined}
                            </View>
                            {cancelado == false &&
                              moment(
                                item.dataConsulta + " " + item.horaConsulta
                              ) < moment() && (
                                <View style={{ width: 170 }}>
                                  <InputLabel></InputLabel>
                                </View>
                              )}
                            {moment(
                              item.dataConsulta + " " + item.horaConsulta
                            ) < moment() ||
                              cancelado ||
                              pago ? undefined : (
                              <View
                                style={{
                                  flexDirection:
                                    window.innerWidth <= 680
                                      ? "row"
                                      : undefined,
                                  justifyContent:
                                    window.innerWidth <= 680
                                      ? "space-evenly"
                                      : undefined,
                                }}
                              >
                                <View>
                                  <Button
                                    variant="contained"
                                    style={{
                                      width:
                                        window.innerWidth <= 680 ? 110 : 170,
                                      color: "#FFF",
                                      backgroundColor: status.find((s) =>
                                        s.cod == item.pago
                                          ? item.statusConsulta
                                          : "noPay"
                                      )?.color,
                                    }}
                                    onClick={async () => {
                                      if (
                                        confirm(
                                          "A confirmação do pagamento pode levar de 5 minutos a 1 dia\ndependendo da forma de pagamento\n\nPagamentos em PIX e Cartão de Crédito são mais rápidos."
                                        ) == true
                                      ) {
                                        if (item?.linkCobranca?.linkCobranca) {
                                          window
                                            .open(
                                              item?.linkCobranca?.linkCobranca,
                                              "_blank"
                                            )
                                            .focus();
                                          await this.getData();
                                        } else {
                                          alert(
                                            "Erro interno, fale com o suporte"
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    {"Pagar"}
                                  </Button>
                                </View>
                                <View>
                                  <Button
                                    variant="contained"
                                    style={{
                                      width:
                                        window.innerWidth <= 680 ? 110 : 170,
                                      marginTop:
                                        window.innerWidth <= 680 ? 0 : 10,
                                      backgroundColor: status.find(
                                        (s) => s.cod == "faltou"
                                      )?.color,
                                      color: "#ffffff",
                                    }}
                                    onClick={async () => {
                                      if (
                                        confirm(
                                          `Deseja realmente cancelar este agendamento ?`
                                        ) == true
                                      ) {
                                        // console.log('GET AG: ', `consultas/${item.id}`, {perfilId: this.props.session})
                                        const auth = JSON.parse(
                                          localStorage.getItem("user_info")
                                        );
                                        //return false;
                                        const del = await DeleteHandler(
                                          `consultas/${item.id}`,
                                          { perfilId: auth.perfilId },
                                          this.props.navigation
                                        );
                                        if (del?.success) {
                                          await this.props.getData();
                                          alert(
                                            `Sucesso!\nAgendamento cancelado.`
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    {"Cancelar"}
                                  </Button>
                                </View>
                              </View>
                            )}
                          </View>
                        </View>
                      </CardContent>
                    </Card>
                  </View>
                );
                return (
                  <ProfissionalItems
                    profile={this.state.profile}
                    key={"profitem-" + key}
                    data={item}
                    setProfileData={(profile) => {
                      //console.log('PERFIL: ', profile);
                      this.setProfile(profile);
                    }}
                  />
                );
              })}
            </ScrollView>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                padding: 5,
                margin: 5,
              }}
            >
              <View>
                {/*this.state.pageOptions?.page <
                  this.state.pageOptions.totalPages && (
                    <Button
                      mode={"InputLabel"}
                      onClick={() => {
                        console.log(this.state.pageOptions);
                        this.getData(this.state.pageOptions);
                      }}
                    >
                      Ver Mais
                    </Button>
                    )*/}
              </View>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              padding: 5,
              margin: 5,
            }}
          >
            <View>
              <InputLabel
                style={{
                  fontSize: 14,
                  color: Colors.black,
                }}
              >
                {this.state?.refreshing
                  ? undefined
                  : "Não há Agendamentos para exibir."}
              </InputLabel>
            </View>
          </View>
        )}
      </View>
    );
  }
}
const mapStateToProps = (store) => ({
  session: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSnackbar, setTimer, setProfileDialog }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);

const styles = {
  container: {
    paddingTop: 30,
    borderRadius: 0,
    backgroundColor: Colors.primary,
    minHeight: "100%",
  },
  secView: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: 15,
    maxHeight: 65,
    paddingBottom: 15,
    bottom: 0,
    backgroundColor: Colors.secondary,
    width: "100%",
  },
  logoContainer: {
    alignItems: "center",
    justifyContent: "center",
    padding: 30,
  },
  logo: {
    height: 150,
    width: 150,
    resizeMode: "contain",
  },
  inputDefaultStyle: {
    height: 40,
  },
};
