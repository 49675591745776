import { BRANDING } from '../../branding';
const Colors = BRANDING?.Colors
    /*{
    primary: '#104267',
    secondary: '#104267',
    tertiary: '#00d5ff',
    quaternary: '#86fce2',
    quinary: '#1d68b9',
    senary: '',
    white: '#FFFFFF',
    black: '#333333',
    grey: '#e7e7e7',
    red: '#f44336',
    border: '#e7e7e7'
}*/
export default Colors