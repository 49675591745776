import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postResetPassword, postAuth, JWT_Decode } from "../../providers/api";
import { setDialog as authDialog, setAuth } from "../../actions/authAction";
import { setLoading, setProfileDialog } from "../../actions/appActions";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Slide from "@material-ui/core/Slide";

import { withRouter } from "react-router-dom";

import InputMask from "react-input-mask";
import Storage from "../../handlers/storage";
import { AuthInternalHandler, GetHandler, PostHandler } from "../../handlers";
import { Checkbox, FormControlLabel, InputAdornment, InputLabel } from "@material-ui/core";
import { BRANDING } from "../../../branding";
import { CheckBoxInput } from "../../components/Forms";
import Colors from "../../commons/Colors";
import { ScrollView, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import {
  GoogleSignin,
  GoogleSigninButton,
  statusCodes,
} from '@react-native-google-signin/google-signin';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  media: {
    height: 140,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Somewhere in your code
const signInGoogle = async () => {
  try {
    await GoogleSignin.hasPlayServices();
    const userInfo = await GoogleSignin.signIn();

    console.log('USER-DATA-GOOGLE', userInfo);
    //this.setState({ userInfo });
  } catch (error) {
    if (error.code === statusCodes.SIGN_IN_CANCELLED) {
      // user cancelled the login flow
    } else if (error.code === statusCodes.IN_PROGRESS) {
      // operation (e.g. sign in) is in progress already
    } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
      // play services not available or outdated
    } else {
      // some other error happened
    }
  }
};

function Login(props) {
  const [loginError, setLoginerror] = React.useState({
    success: false,
    message: "",
  });
  const [resetError, setReseterror] = React.useState({
    success: false,
    message: "",
  });
  const [termsAccepted, setTermsAccepted] = React.useState(true);
  const [viewPass, setViewPass] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [forgotData, setForgotData] = React.useState({
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    perfilId: BRANDING.perfilId,
    codigo: undefined
  });

  const [signup, setSignup] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [resetpwd, setResetpwd] = React.useState(false);
  const [cpf, setCPF] = React.useState("");
  const [termos, setTerms] = React.useState(undefined);
  const [termoSimdoctor, setTermoSimdoctor] = React.useState(false);
  const [termoClinica, setTermoClinica] = React.useState(false);

  const [codigoConfirmacao, setCodigoConfirmacao] = React.useState('');
  const [enviaDepCodConfirmacao, setEnviaDepCodConfirmacao] = React.useState(true);

  const [dados, setDados] = React.useState({
    nome: undefined,
    cpf: undefined,
    email: "anderson@simdoctor.com.br",
    senha: "987654Aa#",
    perfilId: BRANDING.perfilId
  });

  const classes = useStyles();

  useEffect(() => {
    let scheduleData = props?.history?.location?.state?.data;
    let auth_token = localStorage.getItem("auth_token") == '[object Object]' ? '{}' : localStorage.getItem("auth_token");

    let token = JSON.parse(auth_token);
    async function authInternal() {
      if (!token?.token) {
        await AuthInternalHandler(BRANDING?.internal);
      }
    }
    authInternal();

    GetHandler(`administracao/lgpdAutorizacoes/termosCondicoes`, {
      perfilId: BRANDING?.perfilId
    }).then(res => {
      setTerms(res?.data?.data ?? undefined);
    }).catch(e => {
    });

    // return false;
  }, []);

  function onChange(e) {
    let dt = dados;
    if (e.target.id == "cpf") {
      if (e.target.value.replace(/\D/g, "").length >= 11) {
        return false;
      }
      dt[e.target.id] = e.target.value.replace(/\D/g, "");
    } else {
      dt[e.target.id] = e.target.value;
    }

    setDados(dt);
  }

  async function onSubmitReset(e) {
    e.preventDefault();
    setloading(true);
    let data = await postResetPassword(forgotPassword == true ? { ...forgotData, email: dados.email } : { email: dados.email });
    if (data !== undefined) {
      if (data?.data?.success) {
        setLoginerror({ success: false, message: "" });
        setloading(false);

        if (forgotPassword == true) {
          setForgotPassword(false)
          alert(data?.data?.message)
          window.location.href = "/login";

        }
        setForgotPassword(true)
      } else {
        setLoginerror({ success: true, message: "" });
        setloading(false);
      }
      setReseterror({ success: true, message: data.data.message });
    }
  }
  // Cadastro
  const registerHandler = async () => {

    if (termoSimdoctor != true || (termos?.termoClinica?.status == true && termoClinica != true)) {
      alert(
        "É necessário aceitar os termos de uso para se registrar."
      );
      return false;
    } else {

      let data = {
        nome: dados?.nome.split(" ")[0],
        sobrenome: dados?.nome.replace(dados?.nome.split(" ")[0] + " ", ""),
        email: dados?.email,
        senha: dados?.senha,
        cpf: dados?.cpf,
        perfilId: BRANDING?.perfilId,
        termoSimdoctor,
        enviaDepCodConfirmacao,
        //codigoFacebook: props.route?.params?.data?.id ?? "",
        //urlFotoFacebook: props.route?.params?.data?.picture?.data?.url,
      }

      if (termos?.termoClinica?.status != undefined) {
        data.termoClinica = termoClinica;
      };

      if (enviaDepCodConfirmacao == false) {
        data.codigoConfirmacao = codigoConfirmacao;
      }

      const register = await PostHandler(
        //"registrarPacienteLogin",
        "pacientes",
        data,
        props.navigation
      );
      if (register?.data?.success ?? register?.success) {
        let message = register?.data?.message ?? "Enviado com Sucesso!";
        message += enviaDepCodConfirmacao ? ' via ' : '';
        let from = [];

        if (register?.data?.data?.sms == true) {
          from.push('SMS');
        }

        if (register?.data?.data?.email == true) {
          from.push('E-Mail');
        }

        if (register?.data?.data?.whatsapp == true) {
          from.push('WhatsApp');
        }

        alert(message + from.join(', '));
        setEnviaDepCodConfirmacao(false);

        if (codigoConfirmacao?.length > 0) {
          let token = JSON.parse(localStorage.getItem("auth_token") ?? {});
          let authData = await postAuth({ ...dados, perfilId: BRANDING.perfilId }, token?.token);

          if (authData?.error == undefined) {
            if (authData.data.length > 0 && authData.data[0]?.id > 0) {
              localStorage.setItem("user_info", JSON.stringify(authData.data[0]));
              props.setAuth(authData.data[0]);
              
            }
          }
          window.location.href = "/myprofile";
          
        }
        return;
      }

      alert(
        "Problema ao cadastrar.\n" +
        (register?.message ?? register?.data?.message ?? "")
      );
    }
  };
  // Login
  async function onSubmit(e) {
    e.preventDefault();
    if (signup == true) {
      await registerHandler();
      return true;
    }
    let scheduleData = props?.history?.location?.state?.data;
    setloading(true);

    let token = JSON.parse(localStorage.getItem("auth_token") ?? {});
    let data = await postAuth({ ...dados, perfilId: BRANDING.perfilId }, token?.token);

    if (data?.error == undefined) {
      if (data.data.length > 0 && data.data[0]?.id > 0) {
        localStorage.setItem("user_info", JSON.stringify(data.data[0]));
        //localStorage.setItem("token", data.data[0].token);
        props.setAuth(data.data[0]);
        setLoginerror({ success: false, message: "" });
        setloading(false);
        let schedule = scheduleData;

        if (scheduleData?.data || scheduleData?.favorito != undefined) {
          let schedule = scheduleData;

          schedule.pacienteId = data.data[0]?.id;
          schedule.perfilId = data.data[0]?.perfilId;
          schedule.paciente = {
            nome: data.data[0]?.nome
              ? `${data.data[0]?.nome} ${data.data[0]?.sobrenome}`
              : "",
            cpf: data.data[0]?.cpf ?? "",
            email: data.data[0]?.email ?? "",
          };

          if (scheduleData?.favorito == true) {
            let req = await PostHandler(
              `pacientes/${schedule?.pacienteId}/favoritos?perfilId=${schedule?.perfilId}`,
              {
                idDoutor: schedule?.doutorId,
              }
            );

            if (req?.data?.success) {
              window.location.href = "/favorites";
            }

            return;
          } else {
            const scheduling = await PostHandler(
              `consultas`,
              schedule,
              props.navigation
            );

            console.log('2 - Agendamento: ', scheduling)

            if (scheduling?.data?.success == true) {
              let body =
                "O seu horário foi reservado, e será confirmado após efetuar o pagamento !";
              body +=
                "\nDeseja ser redirecionado para pagamento da sessão agora ?";
              props.setProfileDialog({
                title: "Atenção",
                body,
                open: true,
                type: "yesno",
                onSave: () => {
                  window.open(
                    scheduling?.data?.data?.linkCobranca?.linkCobranca,
                    "_blank"
                  );
                  window.location.href = "/history";
                },
                onCancel: () => {
                  window.location.href = "/history";
                },
              });
            } else {
              props.setProfileDialog({
                title: "Atenção ",
                body: scheduling?.data?.message ?? 'Não foi possível realizar o seu agendamento por favor, tente novamente mais tarde!',
                open: true,
                singleButton: true,
                type: "yesno",
              });
            }
          }
        } else {
          window.location.href = "/";
        }
      } else {
        setLoginerror({ success: true, message: data.data.message });
        setloading(false);
      }
    }
  }
  //

  if (enviaDepCodConfirmacao == false) {
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        <div>
          <div style={{ marginTop: 30, backgroundColor: Colors.white, maxWidth: 950, padding: 10, borderRadius: 10 }}>
            <ScrollView style={{ innerHeight: 170 }}>
              <div>
                <View
                  style={{
                    marginTop: 10,
                    paddingBottom: 25,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <InputLabel style={{ marginBottom: 15 }}>Insira o código de confirmação</InputLabel>
                  <TextField
                    size="small"
                    style={{ width: window.innerWidth >= 680 ? 180 : "100%" }}
                    label="Código"
                    variant="outlined"
                    value={codigoConfirmacao}
                    onChange={(e) => {
                      setCodigoConfirmacao(e.target.value);
                    }}
                  />
                </View>
              </div>
            </ScrollView>
          </div>
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
            <Button disabled={loading} variant={"contained"} color={"primary"} onClick={registerHandler}>
              Enviar
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div style={{ marginBottom: 25, marginTop: 35 }}>
        <img
          src={BRANDING?.loginLogo?.img}
          style={{ display: "flex", margin: "auto", marginBottom: 15 }}
          height={BRANDING?.loginLogo?.height}
        />
      </div>
      {!resetpwd ? (
        <form id="loginform" onSubmit={onSubmit}>
          <Card className={classes.root}>
            <CardContent>
              {signup == true && (
                <div>
                  <div>
                    <TextField
                      error={loginError.success}
                      helperText={loginError.success ? loginError.message : ""}
                      type="text"
                      fullWidth
                      id="nome"
                      label="Nome"
                      variant="outlined"
                      onChange={onChange}
                      onBlur={onChange}
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      error={loginError.success}
                      helperText={loginError.success ? loginError.message : ""}
                      type="text"
                      fullWidth
                      id="cpf"
                      label="CPF"
                      variant="outlined"
                      //onBlur={onChange}
                      onChange={(e) => {
                        let dt = dados;
                        let val = e.target.value.replace(/\D/g, "");

                        if (val.length > 11) {
                          return false;
                        }

                        dt["cpf"] = val; ///.replace(/\D/g, "");
                        setDados(dt);
                        setCPF(e.target.value)
                        console.log(e.target.value);
                      }}
                      value={(cpf ?? "")?.replace(/\D/g, "")?.match(/.{1,3}/g)?.join(".")?.replace(/\.(?=[^.]*$)/, "-")}
                    />
                  </div>
                  <br />
                </div>
              )}
              {/** LOGIN */}
              <div>
                <TextField
                  error={loginError.success}
                  helperText={loginError.success ? loginError.message : ""}
                  type="text"
                  fullWidth
                  id="email"
                  label="E-mail"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
              <br />

              <div>
                <TextField
                  error={loginError === true ? true : false}
                  //helperText={ loginError ? "E-mail ou senha inválidos." : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TouchableOpacity onPress={() => {
                          setViewPass(!viewPass)
                        }}>
                          {viewPass ? <VisibilityOff /> : <Visibility />}
                        </TouchableOpacity>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  id="senha"
                  type={viewPass ? "text" : "password"}
                  label="Senha"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
            </CardContent>

            <CardActions style={{ flexDirection: "column" }}>
              <div
                style={{
                  marginBottom: 10,
                  marginLeft: 10,
                  alignSelf: "start",
                  cursor: "pointer",
                  color: "#025ea2",
                }}
              >
                {signup != true ? (
                  <FormControlLabel
                    control={<Checkbox color="primary" defaultChecked />}
                    label="Ficar conectado nesse computador"
                  />
                ) : undefined}

                <div style={{ marginTop: 10 }}>
                  {signup == false ? (
                    <div>
                      <br />
                      <a
                        onClick={() => {
                          setResetpwd(true);
                        }}
                      >
                        Esqueceu a senha?
                      </a>
                      <br />
                      <br />
                      Não possui uma conta ?{" "}
                      <a onClick={() => setSignup(true)}>Cadastre-se</a>
                    </div>
                  ) : (
                    <div>
                      Já possui uma conta ?{" "}
                      <a onClick={() => setSignup(false)}>Entre</a>
                      <div style={{ marginTop: 15, justifyContent: 'start', flexDirection: 'row' }}>
                        {termos?.termoClinica?.status == true ? (
                          <div>
                            <FormControlLabel
                              control={<Checkbox
                                color="primary"
                                defaultChecked={false}
                                onChange={(e) => {
                                  console.log('TERMO: ', e)
                                  setTermoClinica(e.target?.checked)
                                }}
                              />}
                              label={<small>Aceito os termos e condições de serviços do(a) Clinica <br /><a href={termos?.termoClinica?.url}>Leia aqui</a></small>}
                            />
                          </div>
                        ) : undefined}

                        {termos?.termoSimdoctor?.status == true ? (
                          <div>
                            <FormControlLabel
                              style={{ fontSize: 8 }}
                              control={<Checkbox
                                color="primary"
                                defaultChecked={false}
                                onChange={(e) => {
                                  setTermoSimdoctor(e.target?.checked)
                                }}
                              />}
                              label={<small>Aceito os termos e condições de serviços da SimDoctor <br /><a href={termos?.termoSimdoctor?.url}>Leia aqui</a></small>}
                            />
                          </div>
                        ) : undefined}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <br />
              {/* {!loading && (
                <Button
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                disableElevation
                onClick={async () => {await signInGoogle()}}
                style={{
                  marginLeft: 0,
                  //background: 'linear-gradient(45deg, #025ea2 30%, #0086e8 90%)',
                }}
              >
                {"Conectar com o Google"}
              </Button>
              )} */}
              {!loading ? (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  color="primary"
                  disableElevation
                  type="submit"
                  form="loginform"
                  style={{
                    marginLeft: 0,
                    //background: 'linear-gradient(45deg, #025ea2 30%, #0086e8 90%)',
                  }}
                >
                  {signup == false ? "Entrar" : "Cadastrar"}
                </Button>
              ) : (
                <CircularProgress style={{ margin: "auto" }} />
              )}
            </CardActions>
          </Card>
        </form>
      ) : (
        <form id="loginform" onSubmit={onSubmitReset}>
          <Card className={classes.root}>
            <CardContent>
              <div>
                {/*<InputMask id="username" label="Usuário" mask="999.999.999-99" maskChar=" " onChange={onChange}>
                {(inputProps) =>
                  <TextField
                  error={loginError === true ? true : false}
                  helperText={ loginError ? "Usuário ou senha invalidos." : ""}
                  fullWidth id="username" label="Usuário" variant="outlined" onChange={onChange} />
                }
              </InputMask> */}
                <TextField
                  type="text"
                  fullWidth
                  id="email"
                  label="E-mail"
                  variant="outlined"
                  onChange={onChange}
                  onBlur={onChange}
                />
              </div>
              {forgotPassword == true ? (
                <div>
                  <br />
                  <div>
                    <TextField
                      fullWidth
                      id="codigo"
                      type="text"
                      label="Código de Confirmação"
                      variant="outlined"
                      onChange={(e) => {
                        let dt = forgotData;
                        dt['codigo'] = e.target.value
                        setForgotData(dt);
                      }}
                      onBlur={(e) => {
                        let dt = forgotData;
                        dt['codigo'] = e.target.value
                        setForgotData(dt);
                      }}
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      fullWidth
                      id="password"
                      type="password"
                      label="Nova senha"
                      variant="outlined"
                      onChange={(e) => {
                        let dt = forgotData;
                        dt['password'] = e.target.value
                        setForgotData(dt);
                      }}
                      onBlur={(e) => {
                        let dt = forgotData;
                        dt['password'] = e.target.value
                        setForgotData(dt);
                      }}
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      fullWidth
                      id="confirmPassword"
                      type="password"
                      label="Confirme a nova senha"
                      variant="outlined"
                      onChange={(e) => {
                        let dt = forgotData;
                        dt['confirmPassword'] = e.target.value
                        setForgotData(dt);
                      }}
                      onBlur={(e) => {
                        let dt = forgotData;
                        dt['confirmPassword'] = e.target.value
                        setForgotData(dt);
                      }}
                    />
                  </div>
                </div>
              ) : undefined}
            </CardContent>

            <CardActions style={{ flexDirection: "column" }}>
              <div
                style={{
                  marginBottom: 10,
                  marginLeft: 10,
                  alignSelf: "start",
                  cursor: "pointer",
                  color: "#025ea2",
                }}
              >
                <a
                  onClick={() => {
                    setResetpwd(false);
                  }}
                >
                  Login
                </a>
              </div>
              <br />
              {!loading ? (
                <div>
                  <InputLabel style={{ color: Colors.warning }}>{resetError.success ? resetError.message : ""}</InputLabel>
                  <br />
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    color="primary"
                    disableElevation
                    type="submit"
                    form="loginform"
                    style={{
                      marginLeft: 0,
                      background:
                        "linear-gradient(45deg, #025ea2 30%, #0086e8 90%)",
                    }}
                  >
                    SOLICITAR
                  </Button>
                </div>
              ) : (
                <CircularProgress style={{ margin: "auto" }} />
              )}
            </CardActions>
          </Card>
        </form>
      )}
    </div>
  );
}
const mapStateToProps = (store) => ({
  aDialog: store.authReducer.authDialog,
  data: store.authReducer.data,
  loading: store.appReducer.loading,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setProfileDialog, setLoading, authDialog, setAuth, JWT_Decode },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
