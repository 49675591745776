export function toPhone(o) {
    let v = mphone(o);
    setTimeout(function () {
        v = mphone(o);
        if (v != o) {
            o = v;
        }
    }, 1);
    return v;
}

function mphone(v) {
    var r = v.replace(/\D/g, "");

    r = r.replace(/^0/, "");

    if (r.length > 10) {
        return r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
        return r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2$3");
    } else if (r.length > 2) {
        return r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (r.length == 0) {
        return r.replace(/^(\d\d)(\d{0,5})/, "")
    }
    else {
        return r.replace(/^(\d*)/, "($1");
    }
}

export function toCep(v) {
    var r = v.replace(/\D/g, "");

    // r = r.replace(/^0/, "");

    if (r.length > 5) {
        return r.replace(/^(\d{5})(\d{0,3}).*/, "$1-$2");
    } else if (r.length == 0) {
        return r.replace(/^(\d\d)(\d{0,5})/, "")
    }
    else {
        return r.replace(/^(\d*)/, "$1");
    }
}

export function toCNPJ(v) {
    var r = v.replace(/\D/g, "");
    // .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
    // r = r.replace(/^0/, "");

    if (r.length > 12) {
        return r.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3\/$4-$5");
    } else if (r.length > 8) {
        return r.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?/, "$1.$2.$3\/$4");
    } else if (r.length > 5) {
        return r.replace(/^(\d{2})(\d{3})?(\d{3})?/, "$1.$2.$3");
    } else if (r.length > 2) {
        return r.replace(/^(\d{2})(\d{3})?/, "$1.$2");
    } else if (r.length == 0) {
        return r.replace(/^(\d{2})/, "")
    }
    else {
        return r.replace(/^(\d*)/, "$1");
    }
}

export function toCPF(v) {
    var r = v.replace(/\D/g, "");

    // r = r.replace(/^0/, "");

    if (r.length > 11) {
        return r.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");
    } else if (r.length > 9) {
        return r.replace(/^(\d{3})(\d{3})?(\d{3})?/, "$1.$2.$3");
    } else if (r.length > 6) {
        return r.replace(/^(\d{3})(\d{3})?/, "$1.$2");
    } else if (r.length > 0) {
        return r.replace(/^(\d{3})/, "$1")
    }
    else {
        return r.replace(/^(\d*)/, "$1");
    }
}