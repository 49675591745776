import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { StyledBadge } from "../../Custom";
import { setMenu, setLoading } from "../../../actions/appActions";
import { setDialog as authDialog, setAuth } from "../../../actions/authAction";
import { checkImageUrl } from "../../../providers/commonMethods";
/** Assets */
import { fade, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import SearchIcon from "@material-ui/icons/Search";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";

import Avatar from "@material-ui/core/Avatar";
import { styles, StyledAppBar } from "./style.js";
import { red } from "@material-ui/core/colors";
import {
  PersonOutlined,
  Timer10Outlined,
  TimerOutlined,
} from "@material-ui/icons";
import { TouchableHighlight } from "react-native";
import { BRANDING } from "../../../../branding";

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  search: {
    display: "none",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function Header(props) {
  // TIMER
  const [timer, setTimer] = React.useState("");
  //const [authData, setAuthData] = React.useState(undefined);
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(true);
  const [uri, setURI] = useState("/");
  const [counter, setCounter] = useState(
    parseInt(localStorage.getItem("sessionTime"))
  ); // sec to minute #900 to 15 minutes

  /*useEffect(() => {
    let authData1 = localStorage.getItem('user_info');
    setAuthData(JSON.parse(authData1));
    console.log('AAUTH: ', props.auth, authData)
    
  }, [])*/
  useEffect(() => {
    let intervalId;
    //localStorage.setItem("sessionTime", 900);
    if (isActive) {
      intervalId = setInterval(() => {
        setURI(window.location.pathname);
        /*let count = parseInt(localStorage.getItem("sessionTime"));
        if(count <= 0){
          logoutClick();
        }
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);
 
        const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}`: secondCounter;
        const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}`: minuteCounter;
 
        setSecond(computedSecond);
        setMinute(computedMinute);*/
        //setCounter(counter => parseInt(localStorage.getItem("sessionTime")));
        //localStorage.setItem("sessionTime", count -1)
      }, 1000)
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter])

  //END TIMER
  let authData = JSON.parse(localStorage.getItem("user_info"));

  const cliente =
    JSON.parse(localStorage.getItem("cliente")) === null
      ? { logo: undefined }
      : JSON.parse(localStorage.getItem("cliente"));
  const doEffect = () => {
    if (
      props.auth === undefined &&
      JSON.parse(localStorage.getItem("user_info")) !== null
    )
      props.setAuth(JSON.parse(localStorage.getItem("user")));
  };

  const classes = useStyles();
  const toggleMenu = () => {
    props.setMenu(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const usrClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutClick = () => {
    //console.log(props.auth)
    localStorage.removeItem("user");
    localStorage.removeItem("user_info");
    localStorage.removeItem("token");
    props.setAuth(undefined);
    setAnchorEl(null);
    window.location.href = "/";
  };
  let hostUrl = "https://services.yamitec.com";
  //console.log(hostUrl+"/"+cliente.logo);
  let isLogo = checkImageUrl(cliente.logo) ? true : false;

  return (
    <div style={{marginBottom: window.innerWidth < 720 ? 55 : undefined}}>
      {" "}
      {props.loading === true ? <CircularProgress /> : null}{" "}
      <StyledAppBar position="fixed">
        <Toolbar style={{ height: 35 }}>
          {" "}
          {/*//window.innerWidth >= 767 &&
                            <IconButton edge="start" onClick={() => toggleMenu()} className={classes.menuButton} color="inherit" aria-label="menu">
                              <MenuIcon />
                            </IconButton>*/}

          <Typography variant="h6" className={classes.title} style={{ lineHeight: 1 }}>
            {1 == 2 && uri != "/" ? (
              <a href="/" style={{ textDecoration: 'none', color: 'white' }}>
                <img
                  height={BRANDING?.loginLogo?.height}
                  style={{
                    marginRight: 15,
                    marginTop: 10
                  }}
                  src={BRANDING?.loginLogo}
                />
              </a>
            ) : (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start'

              }}>
                <div>
                  <a href="/" style={{ textDecoration: 'none', color: 'white' }}>

                    {BRANDING?.titleHeader}
                  </a>
                </div>
                <div>
                  <small style={{ fontSize: 14 }}>{BRANDING.subtitleHeader}</small>
                </div>
              </div>
            )}{" "}
          </Typography>

          {authData && window.innerWidth >= 720 &&
            <div style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <Button
                color="inherit"
                variant="text"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Buscar
              </Button>
              <Button
                color="inherit"
                variant="text"
                onClick={() => {
                  props.setProfileDialog({ open: true, type: 'profile' });
                }}
              >
                Perfil
              </Button>
              <Button
                color="inherit"
                variant="text"
                onClick={() => {
                  window.location.href = "/favorites";
                }}
              >
                Meus Favoritos
              </Button>
              <Button
                color="inherit"
                variant="text"
                onClick={() => {
                  window.location.href = "/history";
                }}
              >
                Meus Agendamentos
              </Button>
            </div>
          }

          <div>
            {!authData &&
              (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                {window.innerWidth >= 720 && <div style={{ marginRight: 15, maxWidth: 290 }}>
                  Você é um profissional? Junte-se a nós! <a style={{ color: 'white' }} href="https://ibac.com.br/junte-se-a-nos/" target="_blank">Clique aqui</a> para mais detalhes
                </div>}
                <Button
                  color="inherit"
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                >
                  Entrar
                </Button>
              </div>)}
          </div>
          {authData !== null ? (
            <div>
              {/*window.innerWidth >= 720 && (
                <Typography>
                  Sessão: {minute}: {second}{" "}
                </Typography>
              )*/}
              <Button
                color="inherit"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={usrClick}
              >
                <Avatar src={(authData?.urlFoto ?? "").length > 0 ? authData?.urlFoto : undefined} alt={authData?.nome}> {(authData?.nome ?? 'Pa')?.charAt(0)} </Avatar>{" "}
                &nbsp; {window.innerWidth >= 767 && (authData?.nome ?? "Paciente")}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {window.innerWidth < 720 && (
                  <MenuItem disabled>
                    {" "}
                    <PersonOutlined /> {authData?.nome}
                  </MenuItem>
                )}
                <MenuItem onClick={() => {
                  // props.setProfileDialog({ open: true, type: 'profile' });
                  window.location.href = "/myprofile"
                }}>
                  Meu Perfil
                </MenuItem>
                <MenuItem onClick={() => {
                  //console.log('Modal Dependentes')
                  window.location.href = "/dependents"
                  //props.setProfileDialog({ open: true, type: 'dependentes' });
                }}>
                  Dependentes
                </MenuItem>
                <MenuItem onClick={() => window.location.href = "/favorites"}>
                  Meus Favoritos
                </MenuItem>
                <MenuItem onClick={() => window.location.href = "/history"}>
                  Meus Agendamentos
                </MenuItem>
                <MenuItem onClick={() => {
                  props.setProfileDialog({ open: true, type: 'help' });
                }}>
                  Ajuda
                </MenuItem>
                <MenuItem onClick={logoutClick}>
                  <PowerSettingsNewIcon style={{ color: red[500] }} />
                  Sair{" "}
                </MenuItem>{" "}
              </Menu>{" "}
            </div>
          ) : undefined}
        </Toolbar>{" "}
        {window.innerWidth < 720 && <Toolbar>
         <div style={{ marginRight: 15 }}>
            Você é um profissional? Junte-se a nós! <a style={{ color: 'white' }} href="https://ibac.com.br/junte-se-a-nos/" target="_blank">Clique aqui</a> para mais detalhes
          </div>
        </Toolbar>}
      </StyledAppBar>
    </div>
  );
}
const mapStateToProps = (store) => ({
  open: store.appReducer.open,
  loading: store.appReducer.loading,
  auth: store.authReducer.data,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setMenu, setLoading, authDialog, setAuth }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
