const initialState = { timer: 900, snackbar: { open: false, message: undefined }, open: false, loading: false, profileDialog: { title: undefined, open: false, type: 'profile', onSave: () => undefined, onCancel: () => undefined } }

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MENU':
            return {...state, open: action.payload }
        case 'SET_SNACKBAR':
            return {...state, snackbar: action.payload }
        case 'SET_PROFILE_DIALOG':
            return {...state, profileDialog: action.payload }
        case 'SET_LOADING':
            return {...state, snackbar: action.payload }
        case 'SET_TIMER':
            return {...state, timer: action.payload }
        default:
            return state
    }
}