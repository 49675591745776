
export function toMoney(value, double = false ){
    let total = "0,00";
    if(value){
        if(double){
            total = value+"".replace(/[^0-9.-]+/g,"").replace(".", "").replace(",", ".")
        }
        total = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .replace(/\./g, '-')
        .replace(/,/g, '.')
        .replace(/\-/g, ',');
    }
    //console.log(value, total)
    return total;
    
}