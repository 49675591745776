export const setMenu = (open) => ({
    type: 'SET_MENU',
    payload: open
});

export const setSnackbar = (snackbar) => ({
    type: 'SET_SNACKBAR',
    payload: snackbar
});

export const setProfileDialog = (payload) => {
    //console.log('A: ', payload)
    return ({
        type: 'SET_PROFILE_DIALOG',
        payload
    })
};

export const setLoading = (loading) => ({
    type: 'SET_LOADING',
    payload: loading
});

export const setTimer = (value) => ({
    type: 'SET_TIMER',
    payload: value
});