import React, { Component, Fragment } from "react";
import { View } from "react-native-web";
import { Button, IconButton, InputLabel } from "@material-ui/core";
import { TouchableOpacity } from "react-native";

export default class Pagination extends Component {
  render() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <Button
            variant={"contained"}
            disabled={this.props?.data?.page == 1}
            color={this.props?.data?.page > 1 ? "primary" : "default"}
            onClick={() => {
              let page = Number.parseInt(this.props?.data?.page) - 1;
              this.props.getData({ page });
            }}
          >
            Anterior
          </Button>
        </View>
        {window.innerWidth <= 680 ? (
            <View
            style={{
              padding: 10,
              marginStart: 3,
              marginEnd: 3,
              textAlign: "center",
              backgroundColor: "#e0e0e0",
              color: "#111",
              width: 66.5,
              height: 36.5,
            }}
          >
            <InputLabel>{`${ Number.parseInt(this.props?.data?.page ?? 1)} / ${ Number.parseInt(this.props?.data?.totalPages ?? 1)}`}</InputLabel>
          </View>
        ) : (
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            {[...Array(Number.parseInt(this.props?.data?.totalPages ?? 1))].map(
              (i1, k1) => {
                let totalPages = Number.parseInt(
                  this.props?.data?.totalPages ?? 1
                );
                let page = Number.parseInt(this.props?.data?.page ?? 1);

                let ipage = Number.parseInt(k1) + 1;

                let reticences = (
                    <View
                      style={{
                        padding: 10,
                        marginStart: 3,
                        marginEnd: 3,
                        textAlign: "center",
                        backgroundColor: "#e0e0e0",
                        color: "#111",
                        width: 36.5,
                        height: 36.5,
                      }}
                    >
                      <InputLabel>...</InputLabel>
                    </View>
                  )

                
                if (totalPages >= 6 && ipage != page) {
                  if (
                    ![page - 1, page + 1, page+2].includes(ipage) &&
                    ipage <= totalPages - 2
                  ) {
                    return undefined;
                  }
                }
                return (
                    <>
                   {(totalPages >= 6 &&  ipage == (page >= 3 ? page-2 : page+2)) && (reticences)}
                  <TouchableOpacity
                    onPress={() => {
                      this.props.getData({ page: k1 + 1 });
                    }}
                  >
                    <View
                      style={{
                        padding: 10,
                        marginStart: 3,
                        marginEnd: 3,
                        textAlign: "center",
                        backgroundColor:
                          k1 + 1 == this.props?.data?.page
                            ? "#104267"
                            : "#e0e0e0",
                        color:
                          k1 + 1 == this.props?.data?.page ? "#f1f1f1" : "#111",
                        width: 36.5,
                        height: 36.5,
                      }}
                    >
                      {k1 + 1}
                    </View>
                  </TouchableOpacity>
                  </>
                );
              }
            )}
          </View>
        )}
        <View>
          <Button
            variant={"contained"}
            disabled={this.props?.data?.page == this.props?.data?.totalPages}
            color={
              this.props?.data?.page == this.props?.data?.totalPages
                ? "default"
                : "primary"
            }
            onClick={() => {
              let page = Number.parseInt(this.props?.data?.page) + 1;
              this.props.getData({ page });
            }}
          >
            Próximo
          </Button>
        </View>
      </View>
    );
  }
}
