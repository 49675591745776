
const storeData = async (key, value) => {
    try {
        let storageValue = (typeof value === 'string') ? 
        value : JSON.stringify(value);
        await localStorage.setItem(key, storageValue);
    } catch (e) {
        console.log(e)
    }
}

const getData = async (key) => {
    try {
        const data = await localStorage.getItem(key);
        return data == null ? null : JSON.parse(data)
    } catch (e) {
        console.log(e)
    }
  }

  const Storage = {
      get: (key) => getData(key),
      set: (key, value) => storeData(key, value)
  }

  export default Storage;